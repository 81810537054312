<template>
  <main>
    <div class="not-found">
      Ошибка 404 — страница не найдена
    </div>
  </main>
</template>

<script>
export default {
  name: 'NotFound',

  mounted() {
    this.$root.setMeta('name', 'prerender-status-code', '404')
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
  }

  .not-found {
    font-family: @font-set-header;
    font-size: @font-size-header;
    opacity: 0.5;
    text-align: center;
  }
</style>
