<template>
  <main>
    <div class="forbidden">
      Ошибка 403 — доступ запрещён
    </div>
  </main>
</template>

<script>
export default {
  name: 'Forbidden',

  mounted() {
    this.$root.setMeta('name', 'prerender-status-code', '403')
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
  }

  .forbidden {
    font-family: @font-set-header;
    font-size: @font-size-header;
    opacity: 0.5;
    text-align: center;
  }
</style>
